import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlainLight } from "survey-core/themes";
import { Model, Survey } from "survey-react-ui";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import axiosInstance from "../../../../helpers/axiosIntercepter";
import ResponsesEvaluateurs from "./ResponsesEvaluateurs";


const DetailsCandidatures = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [surveyModel2, setSurveyModel2] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();



  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) + "-" + // Year
          amzDate.slice(4, 6) + "-" + // Month
          amzDate.slice(6, 8) + "T" + // Day
          amzDate.slice(9, 11) + ":" + // Hour
          amzDate.slice(11, 13) + ":" + // Minute
          amzDate.slice(13, 15) + "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const onDownloadFile = async (survey, options) => {
    const { file } = options;
    const { content, name } = file;
  
    if (content && name) {
      try {
        const response = await axiosInstance.get(content, {
          responseType: 'blob',
        });
  
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Failed to download the file. Please try again.');
      }
    } else {
      console.error("File content or name is missing.");
      alert('File information is incomplete.');
    }
  };
  

  useEffect(() => {
    const fetchSurveyResponse = async () => {
      try {
        const responseData = await getSurveyResponseById(id);

        if (responseData) {
          const parsedJson = JSON.parse(
            responseData?.surveyResponse?.program?.form?.fields
          );

          const survey = new Model(parsedJson);
          survey.onDownloadFile.add(onDownloadFile);

          let mainData = responseData.surveyResponse.responses;
          mainData = await updatedFilesDate(mainData);

          survey.data = mainData;


          survey.mode = "display";
          survey.applyTheme(PlainLight);
          survey.locale = "fr";

          setSurveyModel(survey);

          const formJuridique = responseData?.surveyResponse?.juridiqueForm;

          if (formJuridique) {
            const parsedJsonJuridique = JSON.parse(formJuridique?.fildes);
            const surveyJuridique = new Model(parsedJsonJuridique);
            surveyJuridique.onDownloadFile.add(onDownloadFile);

            let juridiqueData = responseData?.surveyResponse?.juridiqueFormResponses;
            juridiqueData = await updatedFilesDate(juridiqueData);
       
  

            surveyJuridique.data = juridiqueData;
            surveyJuridique.mode = "display";
            surveyJuridique.applyTheme(PlainLight);
            surveyJuridique.locale = "fr";
            setSurveyModel2(surveyJuridique);
          }
        }
        setData(responseData);
      } catch (error) {
        console.error("Error fetching survey response:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResponse();
  }, [id]);

  const systemEval = data?.surveyResponse?.evaluationActions.filter(
    (item) => item?.isSystemEvaluation
  );


  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!data) {
    return <div>Data not found</div>;
  }

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails de candidature</h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Status :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${
                data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Brouillon" || data?.surveyResponse?.status === "Brouillon"
                  ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                  : data?.surveyResponse?.status === "Éligible"
                  ? "text-black bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Rejeté"
                  ? "text-red-800 bg-red-100 border border-red-900"
                  : data?.surveyResponse?.status === "Sélectionné"
                  ? "text-black bg-green-100 border border-green-900"
                  : "text-blue-800 bg-blue-100 border border-blue-900"
              }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 text-lg">Nom et prénom :</span>{" "}
            {`${data?.userData?.firstName} ${data?.userData?.lastName}` ||
              "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <hr />
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">
              Description du projet :
            </span>{" "}
            <div dangerouslySetInnerHTML={{ __html: data?.surveyResponse?.program?.description || "N/A" }} />
          </div>
          <hr />

          { systemEval && (systemEval?.length > 0) && 
          <div className="flex flex-col border-2 border-[#E2F1FA]  rounded-lg p-2">
            <h5>Evaluation initiale du systéme:</h5>
            <p>Note : {systemEval[0].note}/{systemEval[0].totalNote}</p>
            <p className="">Status : <span className={` ${systemEval[0].status === "Rejeté"  ? 'text-[#EE5D50]' : 'text-[#05CD99]'} `}> {systemEval[0].status === "Rejeté" ? "Non éligible" : systemEval[0].status}</span></p>
          </div>}

          {/* Render SurveyJS Models */}
          {surveyModel && <Survey model={surveyModel} />}
          {surveyModel2 && (
            <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
              <Survey model={surveyModel2} />
            </div>
          )}


          <ResponsesEvaluateurs arrayOfActions={data?.surveyResponse?.evaluationActions}/>

          <div className="mt-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la liste des candidatures
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCandidatures;
