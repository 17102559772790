import axiosInstance from "../helpers/axiosIntercepter";

/**
 * Fetches all available programs from the server.
 * @returns {Promise<Array>} - An array of programs from the server's response.
 * @throws Will throw an error if the request to fetch programs fails.
 */
export const getAllPrograms = async (page = 1, per_page = 10) => {
  try {
    const response = await axiosInstance.get("/programs", {
      params: {
        page,
        per_page,
      },
    });

    return {
      data: response?.data?.data,
      totalDocs: response?.data?.totalDocs,
      totalPages: response?.data?.totalPages,
      currentPage: response?.data?.page,
      perPage: response?.data?.per_page,
      hasNextPage: response?.data?.hasNextPage,
      hasPreviousPage: response?.data?.hasPreviousPage,
    };
  } catch (error) {
    console.error("Error fetching all programs:", error);
    throw new Error("Failed to fetch programs. Please try again later.");
  }
};

export const getAllProgramsHomePage = async (page = 1, per_page = 10) => {
  try {
    const response = await axiosInstance.get("/programs/home-page", {
      params: {
        page,
        per_page,
      },
    });

    return {
      data: response?.data?.data,
      totalDocs: response?.data?.totalDocs,
      totalPages: response?.data?.totalPages,
      currentPage: response?.data?.page,
      perPage: response?.data?.per_page,
      hasNextPage: response?.data?.hasNextPage,
      hasPreviousPage: response?.data?.hasPreviousPage,
    };
  } catch (error) {
    console.error("Error fetching all programs:", error);
    throw new Error("Failed to fetch programs. Please try again later.");
  }
};

export const getAllProgramsFilters = async () => {
  try {
    const response = await axiosInstance.get(
      `/programs/list-all?page=1&per_page=100`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching all programs:", error);
    throw new Error("Failed to fetch programs. Please try again later.");
  }
};

/**
 * Fetches a specific program by its ID from the server.
 * @param {string} id - The unique identifier of the program.
 * @returns {Promise<Object>} - The program data from the server's response.
 * @throws Will throw an error if the request to fetch the program fails.
 */
export const getProgramById = async (id) => {
  try {
    const response = await axiosInstance.get(`/programs/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching program by ID (${id}):`, error);
    throw new Error("Failed to fetch the program. Please try again later.");
  }
};

export const getProgramToFill = async (programId, keyclockUserId) => {
  try {
    const response = await axiosInstance.get(
      `/programs/get-program-to-fill/${programId}/${keyclockUserId}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching program to fill (${programId}):`, error);

    if (error.response && error.response.status === 400) {
      const customMessage =
        error.response.data?.message || "Program is closed or not accessible";
      throw new Error(customMessage);
    }

    throw new Error(
      "Failed to fetch the program to fill. Please try again later."
    );
  }
};

export const createProgram = async (formData) => {
  try {
    const response = await axiosInstance.post("/programs", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("error creating program", error);
    throw error;
  }
};

export const updateProgram = async (programId, formData) => {
  try {
    const response = await axiosInstance.put(
      `/programs/${programId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error updating program", error);
    throw error;
  }
};

export const updateProgramStatus = async (programId, status) => {
  try {
    const response = await axiosInstance.put(
      `/programs/status/${programId}`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating program status", error);
    throw error;
  }
};
