import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { BsFileEarmarkArrowDown, BsPlusSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import * as XLSX from "xlsx";
import {
  getAllSurveyResponses,
  getAllSurveyResponsesExportPdf,
} from "../../../services/surveyResponse.service";
import { getProgramById } from "../../../services/program.service";
import GetStatusLaureat from "../../../helpers/getStatusLaureat";
// import { FaSortUp, FaSortDown } from "react-icons/fa";
import axiosInstance from "../../../helpers/axiosIntercepter";
import assignCoachIcon from "../../../assets/assignCoachIcon.png";
import { toast, ToastContainer } from "react-toastify";

const ListeCandidatures = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [candidatures, setCandidatures] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const [formByProgramId, setFormByProgramId] = useState(null);
  const [percentage, setPercentage] = useState(null);
  // const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  const statusOptions = [
    { value: "", label: "Tous les statuts" },
    // { value: "Retenue", label: "Retenue" },
    { value: "Sélectionné", label: "Sélectionné" },
    { value: "SOUMIS", label: "Soumis" },
    { value: "Brouillon", label: "Brouillon" },
    { value: "Rejeté", label: "Rejeté" },
  ];

  const fetchCandidatures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllSurveyResponses({
        page: page + 1,
        limit: rowsPerPage,
        searchTerm,
        status,
      });
      setCandidatures(response.data);
      setTotalCount(response.totalDocs);
    } catch (error) {
      console.error("Error fetching candidatures:", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm, status]);

  useEffect(() => {
    fetchCandidatures();
  }, [fetchCandidatures]);

  // useEffect(() => {
  //   if (candidatures.length > 0) {
  //     const getProgramAndParseFields = async () => {
  //       try {
  //         const programDataPromises = candidatures
  //           .map((candidature) => candidature?.program?.id)
  //           .filter(Boolean)
  //           .map(getProgramById);

  //         const programDataArray = await Promise.all(programDataPromises);

  //         const laureatResponsesArray = candidatures.map(
  //           (candidature) => candidature?.responses || {}
  //         );

  //         const fieldsArray = programDataArray.map((program) => {
  //           const form = program?.form;
  //           if (form && form.fields) {
  //             try {
  //               return JSON.parse(form.fields).pages || [];
  //             } catch (error) {
  //               console.error("Error parsing fields:", error);
  //               return [];
  //             }
  //           }
  //           return [];
  //         });

  //         // Calculate percentages without modifying `candidatures` directly
  //         const updatedCandidatures = candidatures.map((candidature, index) => {
  //           const laureatResponses = laureatResponsesArray[index] || {};
  //           const fields = fieldsArray[index] || [];
  //           const totalFields = fields.reduce(
  //             (acc, page) => acc + (page.elements?.length || 0),
  //             0
  //           );
  //           const filledResponses = Object.keys(laureatResponses).filter(
  //             (key) =>
  //               laureatResponses[key] !== null && laureatResponses[key] !== ""
  //           ).length;

  //           const percentage =
  //             totalFields > 0
  //               ? Math.min((filledResponses / totalFields) * 100, 100)
  //               : 0;

  //           return {
  //             ...candidature,
  //             percentage, // Add percentage directly to candidature
  //           };
  //         });

  //         setCandidatures((prev) => {
  //           // Update only if data has changed to avoid infinite loops
  //           if (JSON.stringify(prev) !== JSON.stringify(updatedCandidatures)) {
  //             return updatedCandidatures;
  //           }
  //           return prev;
  //         });
  //       } catch (error) {
  //         console.error("Error fetching programs or parsing fields:", error);
  //       }
  //     };

  //     getProgramAndParseFields();
  //   }
  // }, [candidatures]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    setSelected(
      event.target.checked
        ? candidatures.map((candidature) => candidature.id)
        : []
    );
  };

  const handleClick = (event, id, candidature) => {
    if (candidature?.keycloakCoachId !== null) return;

    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    []
  );

  const handleSearchChange = (event) => debouncedSearch(event.target.value);

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=1800`
      );
      return response.data;
    } catch (error) {
      console.log("Error in updating expired url", error);
      return null;
    }
  };

  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);

        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          if (newUrl?.url) {
            updatedObject[key] = [{ ...file, content: newUrl.url }];
          }
        }
      }
    }

    return updatedObject;
  };

  const handleExportClick = async () => {
    const queryParams = {
      status: status,
    };
    setLoadingExport(true);

    try {
      await getAllSurveyResponsesExportPdf(queryParams);

      toast.success(
        "L'exportation de la liste des candidatures a été réalisée avec succès !",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } catch (error) {
      console.error(
        "Erreur lors de l'exportation de la liste des candidatures.",
        error
      );
      toast.error(
        "Erreur lors de l'exportation de la liste des candidatures.",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
        }
      );
    } finally {
      setLoadingExport(false);
    }
  };

  // const handleExportClick = async () => {
  //   setLoadingExport(true);
  //   const genreOptions = [
  //     { valeur: "Item 4", texte: "Féminin" },
  //     { valeur: "Item 5", texte: "Masculin" },
  //   ];

  //   const villeOptions = [
  //     { valeur: "Item 4", texte: "Bukavu" },
  //     { valeur: "Item 5", texte: "Bunia" },
  //     { valeur: "Item 6", texte: "Boma" },
  //     { valeur: "Item 7", texte: "Goma" },
  //     { valeur: "Item 8", texte: "Kananga" },
  //     { valeur: "Item 9", texte: "Kasangulu" },
  //     { valeur: "Item 10", texte: "Kimpese" },
  //     { valeur: "Item 11", texte: "Kinshasa" },
  //     { valeur: "Item 12", texte: "Kisantu" },
  //     { valeur: "Item 13", texte: "Matadi" },
  //     { valeur: "Item 14", texte: "Mbanza Ngungu" },
  //     { valeur: "Item 15", texte: "Mubji-Mayi" },
  //     { valeur: "Item 16", texte: "Muanda" },
  //     { valeur: "Item 17", texte: "Autre" },
  //   ];

  //   const allResponses = [];
  //   let currentPage = 1;
  //   const pageSize = 100;

  //   try {
  //     while (true) {
  //       const response = await getAllSurveyResponsesExport({
  //         page: currentPage,
  //         limit: pageSize,
  //         status,
  //         searchTerm,
  //       });

  //       const responses = response?.data || [];
  //       allResponses.push(...responses);

  //       if (currentPage >= response?.totalPages) break;
  //       currentPage++;
  //     }

  //     console.log(allResponses, "Fetched survey responses for export");

  //     const candidatesWithUpdatedFiles = await Promise.all(
  //       allResponses.map(async (candidature) => {
  //         const updatedResponses = await updatedFilesDate(
  //           candidature.responses
  //         );

  //         const getGenre = () => {
  //           const question5 = updatedResponses?.question5;
  //           const match = genreOptions.find(
  //             (option) => option.valeur === question5
  //           );
  //           return match ? match.texte : "Non spécifié";
  //         };

  //         const getVille = () => {
  //           const question14 = updatedResponses?.question14;
  //           const question15 = updatedResponses?.question15;

  //           const match = villeOptions.find(
  //             (option) => option.valeur === question14
  //           );

  //           if (match?.valeur === "Item 17") {
  //             return question15 || "Non spécifié";
  //           }

  //           return match ? match.texte : "Non spécifié";
  //         };

  //         const genre = getGenre();
  //         const ville = getVille();
  //         const numéroDeTéléphone = (
  //           updatedResponses?.question11 || "Non spécifié"
  //         ).toString();

  //         let fileUrls = [];
  //         if (updatedResponses) {
  //           for (const [key, value] of Object.entries(updatedResponses)) {
  //             if (Array.isArray(value)) {
  //               value.forEach((file) => {
  //                 if (file.name && file.content) {
  //                   fileUrls.push(file?.content);
  //                 }
  //               });
  //             }
  //           }
  //         }

  //         return {
  //           "Appel à candidature": candidature?.program?.title || "",
  //           "Nom du candidat": `${candidature?.user?.firstName || ""} ${
  //             candidature?.user?.lastName || ""
  //           }`,
  //           "Adresse mail": candidature?.user?.email || "",
  //           Genre: genre,
  //           Ville: ville,
  //           "Nom de l'entreprise":
  //             updatedResponses?.entreprise_participante || "Non spécifié",
  //           "Numéro RCCM": updatedResponses?.numero_rccm || "Non spécifié",
  //           "Numéro de téléphone": numéroDeTéléphone,
  //           "Date de soumission": new Date(
  //             candidature?.updatedAt
  //           ).toLocaleDateString("fr-FR"),
  //           État: `${candidature.percentage.toFixed(2)}%`,
  //           Statut: candidature?.status || "",
  //           "Fichiers soumis":
  //             fileUrls.length > 0 ? fileUrls.join(", ") : "Aucun fichier",
  //         };
  //       })
  //     );

  //     const wb = XLSX.utils.book_new();
  //     const ws = XLSX.utils.json_to_sheet(candidatesWithUpdatedFiles);

  //     ws["!cols"] = [
  //       { wch: 30 }, // Appel à candidature
  //       { wch: 25 }, // Nom du candidat
  //       { wch: 25 }, // Adresse mail
  //       { wch: 15 }, // Genre
  //       { wch: 20 }, // Ville
  //       { wch: 30 }, // Nom de l'entreprise
  //       { wch: 20 }, // Numéro RCCM
  //       { wch: 20 }, // Numéro de téléphone
  //       { wch: 20 }, // Date de soumission
  //       { wch: 15 }, // État
  //       { wch: 15 }, // Statut
  //       { wch: 50 }, // Fichiers soumis
  //     ];

  //     XLSX.utils.book_append_sheet(wb, ws, "Liste des Candidatures");
  //     XLSX.writeFile(wb, "Liste_des_Candidatures.xlsx");

  //     toast.success("Exportation réussie !", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       theme: "colored",
  //     });
  //   } catch (error) {
  //     console.error("Error exporting survey responses:", error);
  //     toast.error("Erreur lors de l'exportation des données.", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       theme: "colored",
  //     });
  //   } finally {
  //     setLoadingExport(false);
  //   }
  // };

  // const handleSortByPercentage = () => {
  //   const newOrder = sortOrder === "asc" ? "desc" : "asc";
  //   setSortOrder(newOrder);
  //   const sortedCandidatures = [...candidatures]?.sort((a, b) => {
  //     const aIndex = candidatures?.indexOf(a);
  //     const bIndex = candidatures?.indexOf(b);
  //     const aPercentage = percentage[aIndex] || 0;
  //     const bPercentage = percentage[bIndex] || 0;

  //     return newOrder === "asc" ? aPercentage - bPercentage : bPercentage - aPercentage;
  //   });
  //   setCandidatures(sortedCandidatures);
  // };

  // const handleDetailsClick = (id) => {
  //   navigate(`/unite_de_gestion-dashboard/liste-condidatures/details/${id}`);
  // };

  const handleAssignCoachClick = () => {
    if (selected.length > 0) {
      const queryParams = selected.map((id) => `ids=${id}`).join("&");
      navigate(`assign-coach?${queryParams}`);
    }
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Liste des candidatures</h1>
      <p className="text-gray-500 text-sm mb-4">
        Suivez le statut des candidatures.
      </p>
      {/* 
      <div className="flex justify-end mb-4">
        <Button
          style={{
            textTransform: "none",
            backgroundColor: selected.length > 0 ? "#0976BC" : "#ccc",
            color: selected.length > 0 ? "white" : "#666",
            cursor: selected.length > 0 ? "pointer" : "not-allowed",
          }}
          className={`px-4 py-2 gap-2 rounded-md mb-0 flex items-center font-medium ${
            selected.length > 0
              ? "hover:bg-[#065A94] transition-colors duration-300"
              : ""
          }`}
          disabled={selected.length === 0}
          onClick={handleAssignCoachClick}
        >
          <img
            src={assignCoachIcon}
            alt="assignCoachIcon"
            width={20}
            height={20}
          />
          Assigner un coach
        </Button>
      </div> */}

      <div className="border rounded-lg p-3 bg-white">
        <div className="flex justify-between items-center mb-3">
          <div className="relative w-1/4">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="search"
              className="py-[12px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none w-full"
              placeholder="Rechercher par nom, mail..."
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex gap-[16px] ">
            <FormControl
              variant="outlined"
              sx={{ width: 160, fontFamily: "Arial, sans-serif" }}
            >
              <Select
                value={status}
                onChange={handleStatusChange}
                displayEmpty
                style={{ "height ": "45px" }}
                IconComponent={() => (
                  <ChevronDownIcon className="w-7 text-gray mr-3" />
                )}
                renderValue={(selected) => {
                  const selectedOption = statusOptions.find(
                    (option) => option.value === selected
                  );
                  return selectedOption ? selectedOption.label : "Status";
                }}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  borderRadius: "4px",
                  fontFamily: "Arial, sans-serif",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #dee2e6",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                    paddingRight: "2.5rem",
                  },
                  "& .MuiSelect-icon": {
                    display: "none",
                  },
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              style={{
                textTransform: "none",
                fontFamily: "Arial, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm ${
                loadingExport ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={!loadingExport ? handleExportClick : null}
              disabled={loadingExport}
            >
              {loadingExport ? (
                <CircularProgress
                  size={20}
                  style={{ marginRight: "8px", color: "#000000" }}
                />
              ) : (
                <BsFileEarmarkArrowDown className="mr-2" />
              )}
              {loadingExport ? (
                <span>Exportation...</span>
              ) : (
                <span>Exporter</span>
              )}
            </Button>

            {/* <Button
              style={{
                textTransform: "none",
                fontFamily: "Arial, sans-serif",
                fontSize: "13px",
                fontWeight: "600",
              }}
              className="flex items-center text-gray-600 px-4 py-2 border border-gray-300 rounded-md hover:bg-transparent hover:border-gray-400 hover:bg-slate-50 hover:shadow-sm"
              onClick={handleExportClick}
            >
              <BsFileEarmarkArrowDown className="mr-2" />
              Exporter
            </Button> */}
          </div>
        </div>

        <TableContainer className="relative overflow-x-auto rounded-lg border border-gray-300">
          <Table>
            <TableHead>
              <TableRow hover>
                <TableCell padding="checkbox">
                  <Checkbox
                  // checked={
                  //   selected.length > 0 &&
                  //   selected.length === candidatures.length
                  // }
                  // onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Nom du candidat</TableCell>
                <TableCell>Adresse mail</TableCell>
                <TableCell>Appel à candidature</TableCell>
                <TableCell>Date de soumission</TableCell>
                <TableCell>
                  {" "}
                  {/* onClick={handleSortByPercentage} style={{ cursor: 'pointer' }} */}
                  État
                  {/* {sortOrder === "asc" ? (
                    <FaSortUp className="inline-block ml-1" />
                  ) : (
                    <FaSortDown className="inline-block ml-1" />
                  )} */}
                </TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : candidatures.length > 0 ? (
                candidatures.map((candidature, index) => (
                  <TableRow
                    hover
                    // onClick={(event) =>
                    //   handleClick(event, candidature.id, candidature)
                    // }
                    role="checkbox"
                    // aria-checked={isSelected(candidature.id)}
                    tabIndex={-1}
                    key={candidature.id}
                    // selected={isSelected(candidature.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                      // disabled={
                      //   candidature.status !== "Sélectionné" ||
                      //   !!candidature?.keycloakCoachId
                      // }
                      // checked={
                      //   candidature.status === "Sélectionné" &&
                      //   isSelected(candidature.id)
                      // }
                      // onChange={(event) =>
                      //   handleClick(event, candidature.id, candidature)
                      // }
                      // style={{
                      //   color:
                      //     candidature.status !== "Sélectionné" ||
                      //     !!candidature?.keycloakCoachId
                      //       ? "#ccc"
                      //       : "",
                      // }}
                      />
                    </TableCell>
                    <TableCell>
                      {candidature?.user?.firstName}{" "}
                      {candidature?.user?.lastName}
                    </TableCell>
                    <TableCell>{candidature?.user?.email}</TableCell>
                    <TableCell>{candidature.program?.title}</TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <span className="p-1 rounded-full bg-gray-200">
                          <CalendarIcon className="h-5 w-5 text-gray-600" />
                        </span>
                        <span>
                          {new Date(candidature?.updatedAt).toLocaleDateString(
                            "fr-FR"
                          )}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {candidature.percentage !== undefined ? (
                        <span>{candidature.percentage.toFixed(2)}%</span>
                      ) : (
                        <span>0%</span>
                      )}
                    </TableCell>

                    <TableCell>
                      <GetStatusLaureat status={candidature.status} />
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/unite_de_gestion-dashboard/liste-condidatures/details/${candidature.id}`}
                        className="no-underline bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-2 py-1 rounded-full text-xs flex items-center justify-center"
                        style={{ minWidth: "50px", whiteSpace: "nowrap" }}
                      >
                        Voir détails
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Aucune donnée affichée
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListeCandidatures;
