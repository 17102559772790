import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Model, Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import { CircularProgress } from "@mui/material";
import EvaluationProcess from "./EvaluationProcess";
const DetailsCandidaturesEvaluateur = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const navigate = useNavigate()

  const { id } = useParams();
  const fetchSurveyResponse = async () => {
    setLoading(true);
    try {
      const data = await getSurveyResponseById(id);

      setData(data);
      if (data) {
        const parsedJson = JSON.parse(
          data?.surveyResponse?.program?.form?.fields
        );
        const survey = new Model(parsedJson);
        survey.data = data.surveyResponse.responses;
        survey.mode = "display";
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        setSurveyModel(survey);
      }
    } catch (error) {
      console.error("Error fetching survey responses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyResponse();
  }, []);

  const systemEval = data?.surveyResponse?.evaluationActions.filter(
    (item) => item?.isSystemEvaluation
  );

  return (
    <>
      {loading ? (
        <div className="p-4 w-full justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
      <div className="border rounded-lg p-4 mt-3 bg-white ml-2 ">
        <div className="space-y-3">
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Status :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${
                data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "À Évaluer"
                  ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                  : data?.surveyResponse?.status === "Éligible"
                  ? "text-black bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Rejeté"
                  ? "text-red-800 bg-red-100 border border-red-900"
                    : data?.surveyResponse?.status === "Sélectionné"
                  ? "text-black bg-green-100 border border-green-900"
                  : "text-blue-800 bg-blue-100 border border-blue-900"
              }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 text-lg">Nom et prénom :</span>{" "}
            {`${data?.userData?.firstName} ${data?.userData?.lastName}` ||
              "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <hr />
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">
              Description du projet :
            </span>{" "}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.surveyResponse?.program?.description || "N/A",
              }}
            />
          </div>
          <hr />

          {/* System evaluation  */}

          { systemEval && (systemEval?.length > 0) && 
          <div className="flex flex-col border-2 border-[#E2F1FA]  rounded-lg p-2">
            <h5>Evaluation initiale du systéme:</h5>
            <p>Note : {systemEval[0].note}/{systemEval[0].totalNote}</p>
            <p className="">Status : <span className={` ${systemEval[0].status === "Rejeté"  ? 'text-[#EE5D50]' : 'text-[#05CD99]'} `}> {systemEval[0].status === "Rejeté" ? "Non éligible" : systemEval[0].status}</span></p>
          </div>}

          {/* Survey Component (Static Data) */}
          {surveyModel && <Survey model={surveyModel} />}
        </div>

        <EvaluationProcess arrayOfActions={data?.surveyResponse?.evaluationActions} id={id} status={data?.surveyResponse?.status}/>
        <div className="mt-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la liste des candidatures
            </button>
          </div>
      </div>
      )
      }
    </>
  );
};

export default DetailsCandidaturesEvaluateur;
